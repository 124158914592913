import I18n from 'lib/i18n';

const getJobPostingSchema = jobOpportunity => {
  const responsibilities = jobOpportunity?.responsibilities?.join('\n') ?? '';
  const description = `${jobOpportunity?.description}\n${responsibilities}`;
  const companyName = jobOpportunity?.companyName;
  const logoUrl = 'https://candidates.jobandtalent.com/logo.png';
  const street = jobOpportunity?.geodatum?.street;
  const streetNumber = jobOpportunity?.geodatum?.streetNumber;
  const locality = jobOpportunity?.geodatum?.locality;
  const region = jobOpportunity?.geodatum?.subdivision?.verboseName;
  const postalCode = jobOpportunity?.geodatum?.postalCode;
  const country = jobOpportunity?.geodatum?.country?.verbose_name;
  const currency = jobOpportunity?.payRate?.currencyCode;
  const valueBaseSalary = jobOpportunity?.payRate?.baseSalary;
  const salaryPeriod = jobOpportunity?.payRate?.baseSalaryPeriod;
  const fallbackCompanyName = I18n.t('jobfeed.job_detail.company_hidden', {
    location: jobOpportunity?.geodatum?.formattedHiddenLocation
  });

  const valueSalaryPeriod = salaryPeriod ? `"${salaryPeriod === 'hourly' ? 'HOUR' : 'MONTH'}"` : '';

  const address = {
    '@type': 'PostalAddress'
  };

  if (street) {
    address.streetAddress = `${street} ${streetNumber}`;
  }

  if (locality) {
    address.addressLocality = locality;
  }

  if (region) {
    address.addressRegion = region;
  }

  if (postalCode) {
    address.postalCode = postalCode;
  }

  if (country) {
    address.addressCountry = country;
  }

  const baseSalary = {
    '@type': 'MonetaryAmount',
    currency,
    value: {
      '@type': 'QuantitativeValue',
      value: valueBaseSalary,
      unitText: valueSalaryPeriod
    }
  };

  const ldJson = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: jobOpportunity.title,
    description,
    identifier: {
      '@type': 'PropertyValue',
      name: companyName || fallbackCompanyName,
      value: jobOpportunity.id
    },
    datePosted: jobOpportunity.createdAt,
    validThrough: jobOpportunity.validThrough,
    employmentType: 'CONTRACTOR',
    hiringOrganization: {
      '@type': 'Organization',
      name: companyName || fallbackCompanyName,
      logo: logoUrl
    },
    jobLocation: {
      '@type': 'Place',
      address
    }
  };

  if (jobOpportunity?.payRate) {
    ldJson.baseSalary = baseSalary;
  }

  return ldJson;
};

export default getJobPostingSchema;
